<template>
  <div class="pt-3 mb-footer  border-radius-0 border-0" style="background: transparent;">
    <div class="logo-wrapper text-center mb-2">
      <img :src="'/'+this.getSiteProps('login.logo')" alt="logo">
    </div>
    <div class="login-main-wrapper" style="width: 535px;">
      <div class="login-wrapper">
        <div class="card-header">
          <h3>{{ this.getSiteProps('login.member_login_title') || 'Member Login' }}</h3>
        </div>
        <div class="card card-body pt-3 pb-3">
          <form method="post" id="loginForm" @submit.prevent="checkLogin">
            <div class="form-group">
              <label class="form-label">Email or Membership ID</label>
              <div class="input-group mb-1">
                <div class="input-group-append">
                  <div class="input-group-text">
                    <i class="fa fa-user"></i>
                  </div>
                </div>
                <input
                  type="text"
                  v-model="email"
                  placeholder="Email or Membership ID"
                  v-bind:class="{ 'is-invalid': isActiveEmail }"
                  name="email"
                  class="form-control"
                />
              </div>
              <div v-bind:class="{ error: isActiveEmail }">
                {{ errors_email }}
              </div>
            </div>
            <div class="form-group">
              <label class="form-label">Password</label>
              <div class="input-group mb-1">
                <div class="input-group-append">
                  <div class="input-group-text">
                    <i class="fas fa-key"></i>
                  </div>
                </div>
                <input
                :type="passwordFieldType"
                  v-model="password"
                  class="form-control"
                  v-bind:class="{ 'is-invalid': isActivePassword }"
                  placeholder=" Password"
                  name="password"
                />
                <div class="showhide">
                <!-- <i class="fas fa-eye-slash"></i> -->
                <span type="password"  @click="switchVisibility" ><i v-bind:class="{ 'fa-eye-slash': isActive}" class="far fa-eye"></i></span>
                </div>
              </div>
              <div v-bind:class="{ error: isActivePassword }">
                {{ errors_password }}
              </div>
            </div>
            <div class="form-button">
              <b-button
                class="btn-primary btn-transparent mr-1"
                @click="forgotPassword"
                >I forgot my password</b-button
              >
              <b-button type="submit" class="btn-primary btn-red" :disabled="loading">
                <i class="fa fa-circle-notch fa-spin" v-if="loading"></i>
                <span v-else>Login</span>
              </b-button>
               <!-- <hr>
              <b-button style="width:100%;background-color: #05213e;" type="submit" class="btn-primary btn-red" disabled>                            
                 <span>Log in with SSO </span>
              </b-button> -->
            </div>
          </form>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
      <div class="login-register mb-3 login-links-wrap">
        <div class="card-header">
          <h3>{{ this.getSiteProps('login.member_login_club_title') || 'Do you have a Club Account?' }}</h3>
        </div>
        <div class="card card-body mb-0">
          <p class="mb-2">{{ this.getSiteProps('login.member_login_club_text') || 'If you have a club user account, you can login with this link' }}</p>
          <b-button class="btn-primary btn-blue mb-2" @click="clubLogin" :style="{ fontSize: subdomain == 'waterski' && '11px' }"
            >{{ this.getSiteProps('login.member_login_club_btn') || 'Club Login' }}</b-button
          >
        </div>
      </div>
        </div>
        <div class="col-md-6">
      <div class="login-register login-links-wrap">
        <div class="card-header">
          <h3>Do not have an account?</h3>
        </div>
        <div class="card card-body mb-0">
          <p class="mb-2">If you are not registered yet, create your account easily</p>
          <b-button class="btn-primary btn-blue mb-2" @click="register" :style="{ fontSize: subdomain == 'waterski' && '11px' }"
            >Register</b-button
          >
        </div>
      </div>
        </div>
      </div>
    </div>
    
        

   <dashboardfooter/>
  </div>
  
</template>
<style scoped>
.login-links-wrap h3 {
  font-size: 15px;
}
.login-links-wrap .card-header, .login-links-wrap .card-body {
  padding: 5px 15px;
}
.login-links-wrap p{
  font-size: 13px;
}
.login-links-wrap button{
  height: 35px;
  font-size: 14px;
}
.login-section-wrapper {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  padding: 15px 0;
  background-color: #f2f2f2;
}
.login-img {
  width: 100%;
  height: 100vh;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: top;
  object-position: top;
}
.dashboard-qustion {
  text-align: center;
  margin-bottom: 0 !important;
  padding: 20px 0 17px 0;
}
#loginForm{
  width: 499px;
}
</style>
<script>
import axios from "axios";

 import dashboardfooter from "./components/Footer";
import moment from "moment";
export default {
  name: "memberlogin",
  components: {dashboardfooter},
  data() {
    return {
      loading: false,
      email: "",
      password: "",
      errors_email: "",
      errors_password: "",
      error_class: "",
      isActiveEmail: false,
      isActive: false,
      passwordFieldType: "password",
      isActivePassword: false,
    };
  },
  methods: {
    forgotPassword: function() {
      window.open("/member/forgotpassword", "_self");
    },
      switchVisibility() {
      this.passwordFieldType = this.passwordFieldType === "password" ? "text" : "password";      
      this.isActive = !this.isActive;
      },
    register: function() {
      window.open("/memberregistration", "_self");
    },
    checkLogin: function() {
      if (this.email != "" && this.password != "") {
        this.isActiveEmail = false;
        this.isActivePassword = false;
        this.errors_email = "";
        this.errors_password = "";

        this.loading = true;
        axios
          .post(process.env.VUE_APP_URL + "api/member/login", {
            email: this.email,
            password: this.password,
            type:'member'
          })
          .then((response) => {
            if (response.data.status == "success") {
              localStorage.setItem("membertoken", response.data.data.token);
              localStorage.setItem(
                "member",
                JSON.stringify(response.data.data.member)
              );
              localStorage.setItem("time", moment().unix());

              let d = new Date();
              d.setTime(d.getTime() + 60 * 60 * 1000);
              let expires = "expires=" + d.toUTCString();
              let hostName=window.location.hostname;
              let mainDomain=hostName.substring(hostName.lastIndexOf(".", hostName.lastIndexOf(".") - 1) + 1);
              document.cookie = "LMSACCESSTOKEN=" + response.data.data.member.lmsAccessToken + ";domain=."+mainDomain+";" + expires + ";path=/";

              if(response.data.data.active == 1){
                if(this.returnurl !='' && this.returnurl != undefined){
                  window.location.href=this.returnurl;
                }else {
                  this.$router.push({path: "/member/dashboard"});
                  window.location.reload();
                }
              }else{
                this.$router.push({ path: "/member/renew" });
              }

            } else {
              this.errors_password =
                "These credentials do not match our records.";
              this.isActivePassword = true;
              this.loading = false;
            }
          })
          .catch((error) => {
            this.loaded = true;
            if (error.response.status === 422) {
              this.errors = error.response.data.errors || {};
            }
            this.loading = false;
          });
      } else {
        if (!this.email) {
          this.errors_email = "The email field is required.";
          this.isActiveEmail = true;
        } else {
          this.errors_email = "";
          this.isActiveEmail = false;
        }
        if (!this.password) {
          this.errors_password = "The password field is required.";
          this.isActivePassword = true;
        } else {
          this.errors_password = "";
          this.isActivePassword = false;
        }
      }
    },
    clubLogin(){
      this.$router.push("/club/login")
    }
  },

  beforeMount(){
    if(localStorage.membertoken != undefined) this.$router.push("/member/dashboard");
    this.returnurl=this.$route.query.returnurl;
  }
  
};
</script>
<style scoped></style>
